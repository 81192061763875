<template>
    <v-card v-if="loader.loading === true" class="pa-8" elevation="0" max-width="100%" outlined tile flat>
        <v-skeleton-loader
            type="list-item-avatar, table-heading, list-item-three-line, list-item-three-line, list-item-three-line, table-tfoot, table-heading"
            class="border"></v-skeleton-loader>
    </v-card>
    <v-card class="pa-4" elevation="0" max-width="100%" outlined tile flat v-else>
        <div class="mb-8">
            <v-btn text small @click="backToPrevious" class="" color="secondary">
                <v-icon>{{ icons.mdiKeyboardBackspace }} </v-icon> &nbsp;Back to main page
            </v-btn>
        </div>
        <div class="d-flex flex-row">
            <h2 class="pa-0">
                <strong>{{ vacancies.title }}</strong>
            </h2>
            <v-spacer></v-spacer>
            <v-btn icon class="subsecondary" @click="dialog = false">
                <!-- <v-icon color="secondary">{{ icons.mdiClose }}</v-icon> -->
            </v-btn>
        </div>
        <div>
            <v-list-item two-line class="pa-0">
                <v-list-item-avatar size="50" tile class="secondary">
                    <!-- <v-img src="@/assets/images/avatars/profil.svg"></v-img> -->
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>{{ vacancies.company.name }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <v-icon>{{ icons.mdiMapMarker }}</v-icon>
                        <span>{{ vacancies.location + ',' + vacancies.country.name }}</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-row my-4">
            <span class="mr-4">
                <v-icon>{{ icons.mdiBriefcase }}</v-icon>
                {{ vacancies.periodicity }}
            </span>
            <v-spacer></v-spacer>
            <span class="error--text">
                <v-icon color="error" class="mr-1">{{ icons.mdiAlertOutline }}</v-icon>
                Deadline: {{ vacancies.expire_at }}
            </span>
        </div>
        <v-divider></v-divider>
        <div class="px-0" v-html="vacancies.description"></div>
        <!-- <div class="d-flex" v-if="submit === true">
            <v-spacer></v-spacer>
            <v-btn text color="secondary" class="subsecondary text">apply for the job</v-btn>
        </div> -->
    </v-card>
</template>

<script>
import { mdiMapMarker, mdiBriefcase, mdiAlertOutline, mdiKeyboardBackspace } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    components: {},
    data() {
        return {
            dialog: false,
            vacancies: {},
            loader: {
                loading: true,
                valueReady: false
            }
        }
    },
    computed: {
    },
    methods: {
        backToPrevious() {
            this.$router.go(-1)
        },
    },
    beforeMount() {
        this.loader.loading = true
        Drequest.api(`lazyloading.job?dfilters=on&id:eq=${this.$route.params.jobid}&enterprise_id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.vacancies = value
                    });
                    this.loader.loading = false
                    this.loader.valueReady = true
                }
                else {
                    this.loader.loading = false
                    this.loader.valueReady = false
                }
            })
            .catch((err) => {
                this.loader.loading = false
                this.loader.valueReady = false
            });
    },
    setup() {
        const icons = { mdiMapMarker, mdiBriefcase, mdiAlertOutline, mdiKeyboardBackspace }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';
</style>